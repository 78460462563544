@media screen and (max-width: 576px) {
  .col {
    flex-basis: auto;
    flex-grow: 1;
    max-width: 100%;
  }
  .mlButton {
    margin: 0.5rem;
  }
  .logofont {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .pushLogoutMobile {
    margin-top: 1rem;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.sivulinkki {
  font-size: 1.5rem;
  color: blue;
}
